<template>
  <div class="flex flex-col justify-center items-center w-full">
    <progress-circle
      :diameter="size"
      circle-color="#F7F7FF"
      :start-color="scheme"
      :stop-color="scheme"
      :circle-width="width"
      :total-steps="10"
      :animation-duration="animation"
      :completed-steps="completedSteps"
    >
      <div class="rounded-full shadow-md bg-white w-16 h-16 flex items-center pl-2">
        <span class="font-extrabold text-2xl leading-6 text-darkPurple">
          {{ completedSteps }}
        </span>
      </div>
    </progress-circle>
    <span class="font-normal text-sm leading-5 capitalize text-darkPurple whitespace-nowrap">
      {{ appraisalScore }}
    </span>
    <span class="font-bold leading-5 uppercase text-romanSilver whitespace-nowrap"
      style="font-size:10px;">
      {{ average }}
    </span>
  </div>
</template>

<script>
  import { ProgressCircle } from 'vue-progress-circle'

  export default {
    name: "CircularProgressBar",
    components: {
      ProgressCircle
    },
    props: {
      appraisalScore: {
        type: String,
        required: false,
        default: 'Average Appraisal Score',
      },
      average: {
        type: String,
        required: false,
        default: 'Appraisal Average'
      },
      variant: {
        type: String,
        required: false,
        default: 'default'
      },
      completedSteps: {
        type: Number,
        required: false,
        default: 5
      },
      size: {
        type: Number,
        required: false,
        default: 100
      },
      width: {
        type: Number,
        required: false,
        default: 8
      },
      animation: {
        type: Number,
        required: false,
        default: 1000
      },
    },
    computed: {
      scheme(){
        if(this.variant === 'success') return '#13B56A';
        if(this.variant === 'info') return '#2176FF';
        if(this.variant === 'warning') return '#E99323';
        if(this.variant === 'danger') return '#EA3C53';
        return '#878E99';
      }
    },
  };
  </script>