<template>
  <div class="w-full pt-5 pb-5">
    <template>
      <div class="flex items-center px-2">
        <div class="border-r border-romanSilver h-6 flex">
          <BackButton label="Back" @onClick="$router.back()" variant="secondary" />
        </div>
        <h1 class="text-xl text-left font-extrabold mx-4 ">
          Organization Appraisal
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>
    <template>
      <template>
        <Card class="mx-5 my-5 mt-10 px-5 py-5">
          <div class="flex justify-evenly gap-5">
            <div v-for="(item, i) in progressbar" :key="`${item}-${i}`">
              <CircularProgressBar
                :completed-steps="item.completed"
                :variant="item.variant"
                :appraisal-score="item.score"
                :average="item.average"
              />
            </div>
          </div>
        </Card>
      </template>
      <template>
         <Card class="mx-5 my-5 px-5 pb-5">
           <CardFooter reloadcard />
         </Card>
      </template>
      <template>
        <div class="p-5">
          <Table
            :headers="AppHeader"
            :items="items"
            aria-label="Performance History Table"
            class="w-full"
            :has-checkbox="false"
            :has-number="false"
            :loading="false"
            :pagination-list="paginationList"
            style="width:100%"
            id="printMe"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
          >
            <template v-slot:item="{ item }">
              <div v-if="item.year" class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5">
                <span class="text-darkPurple capitalize">
                  {{ item.data.year }}
                </span>
              </div>
              <div v-if="item.appraisalScore">
                <span class="text-center text-sm leading-6 font-normal text-darkPurple">
                  {{ item.data.appraisalScore }}
                </span>
              </div>
              <div v-if="item.moderatedScore" class="flex justify-start items-center gap-2">
                <span class="text-center text-sm leading-6 font-normal text-darkPurple">
                  {{ item.data.moderatedScore.score }}
                </span>
                <div class="py-1 px-2 rounded-md flex gap-1"
                  :class="{
                    'bg-red-200 text-desire': item.data.moderatedScore.status === 'decreasing',
                    'bg-green-200 text-mediumSeaGreen': item.data.moderatedScore.status === 'increasing',
                    'bg-gray-200 text-romanSilver': item.data.moderatedScore.status === 'default'
                  }">
                  <Icon class-name="text-mediumSeaGreen" size="xs"
                    :icon-name="item.data.moderatedScore.status === 'increasing'?
                      'icon-trending-up-green': item.data.moderatedScore.status === 'decreasing'?
                      'icon-trending-down': 'icon-dashed'" />
                  <span>{{ item.data.moderatedScore.value }}</span>
                </div>
              </div>
            </template>
          </Table>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
  import Table from "@scelloo/cloudenly-ui/src/components/table";
  import CircularProgressBar from "@/components/CircularProgressBar";
  import CardFooter from "@/components/CardFooter";
  import Icon from "@/components/Icon";

  export default {
    name: 'OrganisationPerformance',
    components: {
      Card,
      Table,
      BackButton,
      Breadcrumb,
      CardFooter,
      CircularProgressBar,
      Icon
    },
    data() {
      return {
        isLoading: false,
        commentModal: false,
        breadcrumbs: [
          {
            disabled: false,
            text: "Performance",
            href: "Performance",
            id: "Performance"
          },
          {
            disabled: false,
            text: "Performance Appraisal",
            href: "Performance Appraisal",
            id: "PerformanceAppraisal"
          },
          {
            disabled: false,
            text: "Organization Appraisal",
            href: "organisation-appraisal",
            id: "OrganizationAppraisal"
          },
          {
            disabled: false,
            text: "Organisation Performance",
            href: "organisation-performance",
            id: "OrganisationPerformance"
          }
        ],
        progressbar: [
          { completed: 3.14, variant: 'warning', score: 'Average Appraisal Score', average: '4 Year Average'},
          { completed: 4.14, variant: 'info', score: 'Last Appraisal Score', average: '2020 Performance Appraisal'},
          { completed: 4.55, variant: 'success', score: 'Highest Appraisal Score', average: '2019 Performance Appraisal'},
          { completed: 3.49, variant: 'danger', score: 'Lowest Appraisal Score', average: '2017 Performance Appraisal'}
        ],
        AppHeader: [
          { title: "Year", value: "year" },
          { title: "Appraisal Score", value: "appraisalScore" },
          { title: "Moderated Score", value: "moderatedScore" },
        ],
        items: [
          {
            year: '2020',
            appraisalScore: '4.14',
            moderatedScore: { score: 4.10, status: 'increasing', value: '0.03%'},
          },
          {
            year: '2020',
            appraisalScore: '4.14',
            moderatedScore: { score: 4.10, status: 'decreasing', value: '0.03%'},
          },
          {
            year: '2020',
            appraisalScore: '4.14',
            moderatedScore: { score: 4.10, status: 'default', value: '0.03%'},
          },
        ],
        paginationList: {
          page: 1,
          lastPage: 1,
          total: 1,
          from: 1,
          to: 1
        },
      };
    },
  };
</script>
